<template>
  <v-autocomplete
    v-model="pagination.state"
    :items="userState"
    class="mx-4"
    flat
    hide-details
    @change="pagination.skip = 0"
    :label="$t('filters.userFilter.filterLabel')"
    solo-inverted
  ></v-autocomplete>
</template>
<script>
export default {
  name: "UserFilter",
  computed: {
    pagination: {
      get() {
        return this.$store.getters["users/pagination"];
      },
      set(value) {
        this.$store.dispatch("users/setPagination", value);
      }
    }
  },
  methods: {},
  data() {
    return {
      userState: [
        { value: null, text: this.$t("filters.userFilter.all") },
        { value: 0, text: this.$t("filters.userFilter.created") },
        { value: 1, text: this.$t("filters.userFilter.waitingApproval") },
        { value: 2, text: this.$t("filters.userFilter.active") },
        { value: 3, text: this.$t("filters.userFilter.inactive") }
      ]
    };
  }
};
</script>
